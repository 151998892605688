@import-normalize;

@font-face {
  font-family: "SF Pro Rounded"; /*Can be any text*/
  font-weight: 500;
  src: local("SF Pro Rounded"),
    url("./fonts/SF-Pro-Rounded-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Rounded"; /*Can be any text*/
  font-weight: 700;
  src: local("SF Pro Rounded"),
    url("./fonts/SF-Pro-Rounded-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Rounded"; /*Can be any text*/
  font-weight: 600;
  src: local("SF Pro Rounded"),
    url("./fonts/SF-Pro-Rounded-Semibold.ttf") format("truetype");
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

#no-user-select {
  user-select: none;
}

html,
body {
  background: #203f1a;
}

html {
  width: 100%;
  min-height: 100%;
}

/* TODO: Load fonts */
body {
  /* position: fixed; */
  font-family: "SF Pro Rounded", "Manrope", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-style: normal;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #1f2027;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden auto;
}

:root {
  --primary-text-color: #ffffff;
  --secondary-text-color: #9cdb8b;

  --primary-accent-color: #0f762c;
  --secondary-accent-color: #41a105;

  --primary-background-color: #18261c;
  --secondary-background-color: #254124;
  --thirdy-background-color: #26a4e3;

  --primary-background-gradient: linear-gradient(
    85deg,
    #18261c 0%,
    #213426 100%
  );

  --primary-background-transparent: rgba(61, 117, 48, 0.32);

  --background-overlay: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );

  --primary-accent-gradient: linear-gradient(261deg, #ff772b 0%, #ec5500 100%);
  --primary-accent-gradient-v2: linear-gradient(
    261.2deg,
    #ff772b 0%,
    #ec5500 99.69%
  );
  --primary-accent-gradient-v3: linear-gradient(268deg, #f90 0%, #f66b07 100%);
  --primary-accent-gradient-v4: linear-gradient(
    268.43deg,
    #ff9900 -0.32%,
    #f66b07 100.02%
  );

  --primary-accent-gradient-disabled: linear-gradient(
    261.2deg,
    rgba(255, 119, 43, 0.5) 0%,
    rgba(236, 85, 0, 0.5) 99.69%
  );
  --secondary-accent-gradient: linear-gradient(
    90deg,
    #23491a 0%,
    #0e762c 51%,
    #23491a 100%
  );

  --thirdy-accent-gradient: linear-gradient(0deg, #41a105, #41a105);

  --primary-border-color: #f90;
  --primary-border: 2px solid var(--primary-border-color);

  --primary-shine-color: #32c61a;
}
