.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  z-index: 2;
}

.gameLevelName {
  text-shadow: 0 0 12px rgba(251, 141, 8, 0.8);
}
