.bonus {
  background: var(--primary-accent-gradient-v2);
  padding: 4px 12px;
  border-radius: 100px;
}

.sectionTitle {
  margin-bottom: 16px;
}

.drumInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 16px;
}

.iconWrapper {
  position: relative;
}

.drumIcon {
  max-width: 175px;
  position: relative;
  margin-bottom: 16px;
  object-fit: cover;
  z-index: 1;
}

.backShine {
  position: absolute;
  width: 240px;
  height: 100px;
  transform: translate(0%, -8%);
  background: var(--primary-shine-color);

  border-radius: 361px;

  filter: blur(40px);
}

.balance {
  margin-bottom: 24px;
}

.upgradeSection {
  margin-bottom: 24px;
  text-align: center;
}

.upgradeButton {
  margin-bottom: 8px;
}

.upgradeGameLevelButton {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 12px 0px;
  gap: 0px;

  // background: var(--primary-accent-gradient);
  border: none;
  border-radius: 16px;

  box-shadow: 0px 4px 40px 0px #ef5a06;

  cursor: pointer;

  &[aria-disabled="true"] {
    pointer-events: all;
    background: var(--primary-accent-gradient-disabled);
    box-shadow: none;

    & > * {
      color: #fff;
    }
  }
}

.rewardText {
  display: block;
  opacity: 0.8;
}

.freeUpgradeText {
  text-align: center;
  margin: 0px;
  // margin-bottom: 20px;
}

.drumGroup {
  margin-bottom: 24px;
  padding: 8px 12px;
}

.drumGroupItem {
  width: 48%;
  padding: 10px;
  background-color: #4a5d23;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.popupMessage {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--thirdy-accent-gradient);

  width: calc(100vh - 234px);
  color: white;
  padding: 20px 16px;
  border-radius: 20px;
  text-align: center;
  white-space: wrap;

  box-shadow: 0px -24px 24px 0px #1d2d21;

  z-index: 100;
}
