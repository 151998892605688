.airdropModal {
  padding: 24px 0px 16px 0px;
}
.airdropContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topInfo {
  width: 100%;
}

.header {
  margin: 0px;
  justify-content: right;
  position: static;
}

.closeButton {
  position: static;
  width: min-content;
  transform: none;
}

.title {
  margin-bottom: 12px;
  text-align: center;
}

.iconWrapper {
  width: 24px;
  height: 24px;

  margin: 7.5px 0px 7.5px 12px;
}

.imageWrapper {
  position: relative;
  width: 307px;
  height: 240px;
  display: flex;
  justify-content: center;
  margin-top: 10 px;
}

.airdropImage {
  z-index: 10;
  opacity: 1;
}

.glowingEllipse {
  position: absolute;
  width: 307px;
  height: 166px;
  top: 29px;
  left: calc(50% - 153.5px);
  background: rgba(20, 234, 173, 0.8);
  border-radius: 50%;
  filter: blur(40px) brightness(1.2);
  opacity: 0.7;
  box-shadow: 0 0 30px 15px rgba(20, 234, 173, 0.5),
    0 0 60px 30px rgba(20, 234, 173, 0.3);

  mix-blend-mode: screen;
}
.airdropBtn {
  margin-bottom: 12px;
  border-radius: 37px !important;
  box-shadow: none !important;
  background: none !important;
  background-color: #461298 !important;
  z-index: 100;
}

.tgBtn {
  width: 158px;

  border-radius: 16px !important;
  box-shadow: none !important;
  background: linear-gradient(180deg, #2aabee 0%, #229ed9 99.26%) !important;
}

.twitterBtn {
  width: 158px;
  gap: 0px;
  border-radius: 16px !important;
  box-shadow: none !important;
  background: black !important;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.info {
  color: #14eaad;
}
