.text {
  font-family: inherit;
}
.h1 {
  font-size: 32px;
  line-height: 44.8px;
}
.h2 {
  font-size: 28px;
  line-height: 39.2px;
}
.h3 {
  font-size: 24px;
  line-height: 32px;
}
.textXL {
  font-size: 18px;
  line-height: 25.2px;
}
.textL {
  font-size: 16px;
  line-height: 22.4px;
}
.textM {
  font-size: 14px;
  line-height: 19.6px;
}
.textXM {
  font-size: 12px;
  line-height: 16.8px;
}
.textS {
  font-size: 10px;
  line-height: 14px;
}
.slim {
  font-weight: 400;
}
.normal {
  font-weight: 500;
}
.semiBold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.green {
  color: #9cdb8b !important;
}
.darkOrange {
  color: #fd9001;
}
.orange {
  color: #ffa600;
}
.white {
  color: rgba(255, 255, 255, 1);
}
.white50 {
  color: rgba(255, 255, 255, 0.5);
}
.white40 {
  color: rgba(255, 255, 255, 0.4);
}
.white30 {
  color: rgba(255, 255, 255, 0.3);
}
.primary {
  color: rgba(112, 104, 254, 1);
}
.secondary {
  color: rgba(118, 121, 255, 1);
}
.error {
  color: rgba(198, 0, 82, 1);
}
.warn {
  color: rgba(250, 185, 21, 1);
}
.gradient {
  background: linear-gradient(268deg, #f90 0%, #f66b07 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
