.backgroundWrapper {
  padding: 12.4vh 0 !important;
}

.emailModal {
  padding: 44px 26px 26px 34px;
  max-width: 80vw !important;
}

.header {
  margin-bottom: 24px;
}

.title {
  display: block;
  text-align: center;
  margin-top: 40px;
}

.emailContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.emailInput {
  margin-top: 35vh;
  margin-bottom: 18px;
  padding: 16px;
  border: 1px solid var(--primary-border-color);
  border-radius: 12px;

  width: 100%;
  background: var(--input-background);
  color: var(--primary-text-color);

  &::placeholder {
    color: var(--primary-text-color);
  }

  &:focus {
    outline: none;
    border-color: #ead40e !important;
  }
}

.popupMessage {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--thirdy-accent-gradient);

  width: calc(100vh - 234px);
  color: white;
  padding: 20px 16px;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
}
