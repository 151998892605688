.container {
  width: 100%;
  position: relative;
  flex: 1;
  background: url(./Router/bg-pattern.jpg) repeat, #203f1a;
  &::before {
    content: "";
    position: fixed;
    left: 0;
    width: 100%;
    height: 250px;
    bottom: 0;
    background: linear-gradient(0deg, #18261c 16px, rgba(24, 38, 28, 0) 100%);
  }
}
