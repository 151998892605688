.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  width: 100%;
  padding-top: 8px;
}

.heading {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  & > p {
    margin: 0;
  }
}

.taskNav {
  display: flex;
  flex-flow: row nowrap;
  padding: 12px 8px;
  gap: 12px;
  // align-self: center;
  border-radius: 100px;
  background-color: var(--primary-background-transparent);
}

.navButton {
  position: relative;
  flex: 1;
  min-width: calc((100% - 48px) / 3);
}

.newLabel {
  display: flex;
  position: absolute;
  top: -10px;
  right: 0;

  padding: 2px 8px;

  justify-content: center;

  border-radius: 100px;
  background: var(--primary-accent-gradient-v2);
}

.toggleInactive {
  background-color: transparent;
  color: var(--secondary-text-color);
}

.toggleNew {
  border: 1px solid var(--secondary-accent-color);
  background-color: transparent;
  color: var(--secondary-text-color);
}

.taskList {
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.task {
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  background: rgba(61, 117, 48, 0.32);
}

.descriptionContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.description {
  display: flex;
  flex-flow: column;

  &.claimed {
    opacity: 0.5;
  }

  & .title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;

    & .content {
      display: inline-block;
      position: relative;

      & .descButton {
        display: inline-block;
        font-size: 0;
        line-height: 0;
        border: 0;
        vertical-align: bottom;
        margin-left: 4px;
        height: 20px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

.reward {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
}

.actionsContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: center;
  margin-top: 16px;
}

.checkBtn {
  background-color: #e5a21c;

  &:hover,
  &:focus {
    background-color: #c08818;
  }

  &:active {
    background-color: #a47415;
  }
}

.toggleElement {
  display: flex;
  border-radius: 100px;
  background: var(--primary-background-transparent);
  padding: 4px;
  display: flex;
  align-items: center;
  border: none;
}
.toggleLabel {
  width: 100%;
  border-radius: 40px;
  padding: 12px 24px;
  color: #9cdb8b;

  font-family: "SF Pro Rounded";
  font-size: 16px;
  font-weight: 700;
}
.toggleActive {
  background: #41a105;
  color: #fff;
}

.popupMessage {
  position: fixed;
  width: calc(100vw - 32px);
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 16px;

  color: white;
  background: var(--thirdy-accent-gradient);

  border-radius: 20px;
  text-align: center;
  white-space: wrap;

  box-shadow: 0px -24px 24px 0px #1d2d21;

  z-index: 100;
}
