.balanceContainer {
  width: 100%;
  padding: 6px 0px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 12px;

  border-radius: 54px;
  border: var(--primary-border);
  background: linear-gradient(85deg, #18261c 0%, #213426 100%);
}
.balance {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 28px;
}

.balanceContainerWithSpentDrums {
  padding-top: 12px;
  padding-bottom: 12px;
}

.spentDrums {
  display: flex;
  justify-content: center;
  min-width: 200px;
  padding: 4px 0;
  gap: 4px;
  align-self: center;

  border-top: 1px solid #284527;
}

.dailyBonusButton {
  position: absolute;
  right: 16px;
  top: 15px;
  width: 40px;
  height: 40px;
}

.tokenName {
  font-size: 28px;
  line-height: 39.2px;
}
