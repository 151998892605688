.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  z-index: 20;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.content {
  width: calc(100% - 40px);
  padding: 24px 16px 16px;
  border-radius: 44px;
  border: var(--primary-border);
  background: linear-gradient(85deg, #18261c 0%, #213426 100%), #264423;
  z-index: 30;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 32px;
}
.heading {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 16px;
}
.steps {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.list {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  list-style-type: disc;
  padding-left: 24px;

  & li::marker {
    color: rgba(255, 255, 255, 0.5);
  }
}
.ok {
  width: 100%;
  background: #41a105 !important;
  box-shadow: none !important;
}
