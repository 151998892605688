.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

.contentContainer {
  width: 100%;
  padding: 16px !important;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0 !important;

  border-radius: 54px;
  border: var(--primary-border);
  background: linear-gradient(85deg, #18261c 0%, #213426 100%);
}

.balanceContainer {
  margin-top: 12px;
}

.teamWrapper {
  margin-top: 12px;
  border-radius: 40px;
  background: rgba(61, 117, 48, 0.32);
  padding: 8px 0 0 0;
  display: flex;
  flex-flow: column nowrap;
}

.financeContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.claimButton {
  margin-top: 24px;
}
.teamContainer {
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 1px solid #41a105;
}
.team {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
}
.teamList {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;

  &:empty {
    margin-bottom: 0;
  }

  & .member {
    padding: 0 8px 0 4px;
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;

    & .wrapper {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      & .name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 4px;
      }
    }
  }
}

.financeDescr {
  margin-top: 12px;
}

.shareWrapper {
  padding: 12px 16px 16px;
  border-radius: 0 0 40px 40px;
  position: relative;
  &.withCursor {
    background: #284924;
  }
}

.shareMoreUsers {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(
    to top,
    rgba(#284924, 1) 0%,
    rgba(#284924, 1) 20%,
    rgba(#284924, 0.7) 70%,
    rgba(#284924, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  font-weight: 700;
  color: #fff;
  font-size: 22px;
}

.shareWrapperInner {
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1;
  position: relative;
}

.inviteButton {
  padding-top: 11px;
  padding-bottom: 11px;
}

.shareButton {
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #41a105;
}

.progressWrapper {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-top: 24px;
}
.progressHeader {
  font-weight: 700;
}
.progressInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 6px 0 4px;
}

.progress {
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 40px;
  background: #284924;
}
.progressInner {
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #41a105;
}

.popupMessage {
  position: fixed;
  width: calc(100vw - 32px);
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 16px;

  color: white;
  background: var(--thirdy-accent-gradient);

  border-radius: 20px;
  text-align: center;
  white-space: wrap;

  box-shadow: 0px -24px 24px 0px #1d2d21;

  z-index: 100;
}
