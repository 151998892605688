.mainContainer {
  display: flex;
  flex-direction: column;
}

/* Header container */
.headerContainer {
  display: flex;
  justify-content: space-between;

  height: 41px;
  margin-bottom: 12px;
}

.faqButton {
  max-height: 32px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 12px 24px 24px 24px;
  gap: 24px;
  border-radius: 44px;
  border: var(--primary-border);

  background: linear-gradient(85deg, #18261c 0%, #213426 100%), #264423;
}

.statList {
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 12px;
}

.statItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
