.backgroundWrapper {
  padding: 22.4vh 0;
  height: auto;
}

.dailyModal {
  // display: flex;
  // flex-direction: column;
  padding: 24px 16px 16px 16px;
  max-width: 320px;
  // align-items: center;
  // justify-content: center;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}
.closeButton {
  display: none;
}

.checkIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  background-color: var(--secondary-accent-color);
  border-radius: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dailyBonus {
  margin-bottom: 8px;
}

.description {
  text-align: center;
  margin-bottom: 12px;
}

.mainButton {
  margin-top: 32px;
  width: 100%;
}
