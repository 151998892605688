.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  backdrop-filter: blur(10px);
}
.content {
  width: 100%;
  max-width: 560px;
  padding: 24px 24px 48px 24px;
  background-color: rgba(40, 41, 54, 1);
  border-radius: 16px;
  z-index: 30;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
}
.heading {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 16px;
}
.form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
}
.formField {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  & .label {

  }
  & .inputFiled {
    position: relative;

    & .input {
      width: 100%;
      padding: 13px 56px 13px 12px;
      flex: 1;
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      color: white;
      background-color: rgba(31, 32, 39, 1);
      border: 1px solid transparent;
      border-radius: 12px;

      &:disabled {
        pointer-events: none;
        background-color: rgba(155, 161, 241, 0.1);

        &::placeholder {
          color: transparent;
        }
      }

      &::placeholder {
        left: 16px;
        font-size: 16px;
        line-height: 1;
        color: rgba(255, 255, 255, 0.3);
        transition: color 0.3s ease;
      }
      &:not(:disabled):not(.error):hover {
        border-color: rgba(112, 104, 254, 1)
      }
      &:not(:disabled):not(.error):focus {
        border-color: rgba(112, 104, 254, 1);
        box-shadow: 0 0 0 3px rgba(112, 104, 254, 0.5);

        &::placeholder {
          color: transparent;
        }
      }
      &.error {
        border-color: rgba(198, 0, 82, 1);
        box-shadow: 0 0 0 3px rgba(198, 0, 82, 0.5);
      }
    }
    & .rightBtnContainer {
      position: absolute;
      top: 0;
      right: 12px;
      width: 42px;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
  }
  & .errorContainer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
}
