.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  backdrop-filter: blur(15px);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 42;
  padding: 19px 17px;
}

.modalContent {
  position: relative;
  width: 100%;
  max-width: calc(100vh - 34px);
  height: 100%;
  max-height: calc(100vh - 38px);
  padding: 21px 16px;

  color: white;
  background: var(--primary-background-gradient);

  border-radius: 44px;
  border: var(--primary-border);

  overflow-y: auto;
  overflow-x: hidden;

  -webkit-overflow-scrolling: touch;
}

.modalBody {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

// Header
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 4px;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.absoluteCloseButton {
  position: absolute;

  top: 24px;
  right: 14px;
}

.inlineCloseButton {
  margin-left: auto;
}

.popupMessage {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--thirdy-accent-gradient);

  width: calc(100vh - 234px);
  color: white;
  padding: 20px 16px;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
}
