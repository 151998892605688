.drumsList {
  max-height: unset;
  margin-bottom: 20px;
  overflow-y: unset;
}

.drumItem {
  display: flex;
  background-color: var(--secondary-background-color);
  border-radius: 10px;
  padding: 13.5px 12px;
  margin-bottom: 12px;
  align-items: center;
  overflow: visible !important;
  transition: box-shadow 0.3s ease;

  &.activeDrum {
    box-shadow: inset 0 0 0 2px var(--primary-border-color);
  }
}

// .iconWrapper {
//   width: 44px;
//   height: 44px;
// }

// .drumIcon {
//   width: 100%;
//   max-width: 44px;
//   height: auto;
// }

.iconWrapper {
  display: flex;
  width: 44px;
  height: 44px;
  margin-right: 12px;

  align-items: center;
  justify-content: center;
}

.drumIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.drumInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drumName {
  color: white;
  margin-bottom: 2px;
}

.drumBonus {
  color: #ff7f50;
}

.upgradeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.selectedDrum,
.selectDrumBtn {
  background: transparent;

  border: 2px solid var(--primary-accent-color);
}

.selectDrumBtn {
  min-width: 80px;
}
.selectedDrum {
  gap: 4px;
  padding-left: 12px;
}

.upgradeCost {
  display: flex;
  align-items: center;
  margin-top: 6px;
  gap: 4px;
  white-space: nowrap;
}

.coinIcon {
  margin-right: 4px;
  flex-shrink: 0;
}

.drumLevelBar {
  flex-grow: 1;
  height: 4px;
  margin-right: 12px;
}

.upgradeCost {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.coinIcon {
  flex-shrink: 0;
}
