.header {
  margin: 0px;
  justify-content: right;
  position: static;
}

.closeButton {
  position: static;
  width: min-content;
  transform: none;
}

.title {
  margin-bottom: 12px;
  text-align: center;
}

.faqContent:not(:last-child) {
  max-height: unset;
  margin-bottom: 20px;
  overflow-y: unset;
}

.faqItem {
  display: flex;
  flex-direction: row;
  padding: 12px 12px 12px 24px;
  background: var(--background-overlay);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 12px;

  a {
    -webkit-text-fill-color: #f66b07;

    color: transparent;
    text-decoration: underline;
  }
}

.answerText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.faqQuestion {
  display: flex;
  flex-direction: row;

  cursor: pointer;
}

.faqAnswer {
  max-height: 1.5em;
  overflow: hidden;

  &.open {
    max-height: 1000px;
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;

  margin: 7.5px 0px 7.5px 12px;
}
