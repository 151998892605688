.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  z-index: 20;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.content {
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 44px;
  border: var(--primary-border);
  background: linear-gradient(85deg, #18261c 0%, #213426 100%), #264423;
  z-index: 30;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.heading {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.rows {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 8px;
}

.icon {
  box-shadow: 0px 4px 16px 0px rgba(80, 175, 149, 0.32);
  border-radius: 24px;
}

.button {
  width: 100%;
  margin-top: 32px;
}
