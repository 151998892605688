.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.postList {
  position: relative;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  // align-items: center;
  height: 100%;
}

.noNotifications {
  position: absolute;
  top: 35vh;
  text-align: center;
  opacity: 0.5;
}

.post {
  display: flex;
  flex-direction: column;
  gap: 4px;

  max-height: 88px;
  padding: 12px 16px;

  background: var(--background-overlay);
  border-radius: 16px;

  overflow: hidden;
}

.newPost {
}

.opacity6 {
  opacity: 0.6;
}

.postHeader {
  display: flex;

  justify-content: space-between;
}

.postTitle {
  position: relative;

  &.new {
    padding-left: 12px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
    }
  }
}

.newPostIndicator {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.postTime {
  opacity: 0.5;
}

.postText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
