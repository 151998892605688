
.shineWrapper {
  position: relative;
}
.shineToken1, .shineToken2, .shineToken3 {
  position: absolute;
  background: rgba(255, 126, 0, 0.50);
  mix-blend-mode: screen;
  filter: blur(6px);
  border-radius: 100px;
}
.shineToken1 {
  top: 3px;
  left: -7px;
  width: 18px;
  height: 18px;
}
.shineToken2 {
  width: 24px;
  height: 18px;
  right: -5px;
  top: 5px;
  transform: rotate(-41.343deg);
}
.shineToken3 {
  width: 24px;
  height: 18px;
  transform: rotate(-41.343deg);
  left: 30px;
  top: 8px;
}