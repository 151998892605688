.container {
  width: 100%;
  position: relative;
  flex: 1;
  padding: 16px 0 108px 0;
  background: url(./bg-pattern.jpg) repeat, #203F1A;
  &::before {
    content: "";
    position: fixed;
    left: 0;
    width: 100%;
    height: 500px;
    bottom: 0;
    background: linear-gradient(0deg, #18261C 66px, rgba(24, 38, 28, 0.00) 100%);
  }

  &.email {
    padding-bottom: 60px;
    &::before {
      top: 0;
      bottom: auto;
      background: linear-gradient(180deg, #18261C 0%, rgba(24, 38, 28, 0.00) 100%);
    }
  }
}
.wrapper {
  width: 100%;
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  padding: 0 16px;
  z-index: 1;
  position: relative;
}
.confettiContainer {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 50%;
}
