.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.qrContainer {
  width: calc(100% + 32px);
  position: relative;
}
.shine {
  width: 254px;
  height: 169px;
  background: #32C61A;
  filter: blur(50px);
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
}
.qr {
  width: 100%;
  height: auto;
  object-fit: contain;
  z-index: 1;
  position: relative;
}
.text {
  position: relative;
  z-index: 1;
}
