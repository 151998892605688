.dailyBonusList {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.checkbox {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;

  svg {
    width: 18px;
    height: 18px;
  }

  &.completed {
    background: var(--secondary-accent-color);
  }

  &.claimable {
    border: 2px solid var(--secondary-accent-color);
  }

  &.extraGiftClaimable {
    background: rgba(255, 165, 0, 0.2);
    animation: bouncy-pulse 2s ease-in-out infinite;

    box-shadow: 0 0 4px 2px var(--primary-border-color),
      0 0 15px 0px var(--primary-border-color);
  }

  &.currentDay:not(.completed) {
    border: 2px solid var(--secondary-accent-color);
  }
  &.claimedToday {
    position: relative;

    .timeOverlay,
    .extraGift {
      position: absolute;
      width: 51.22px;
      height: 28.73px;
      top: 50%;
      left: 50%;
      mix-blend-mode: screen;
      filter: blur(7px);
      transform: translate(-50%, -50%) rotate(-41.34deg);
      pointer-events: none;
      background: var(--secondary-accent-color);
      border-radius: 50%;
    }

    .extraGift {
      filter: blur(9px);
      background: var(--primary-accent-gradient) !important;
      opacity: 0.7;
    }
  }

  &:not(.completed):not(.bonus):not(.claimedToday) {
    background: #3d753052;
  }

  @keyframes bouncy-pulse {
    0% {
      transform: translateY(0) scale(1) rotate(-5deg);
    }
    50% {
      transform: translateY(-3px) scale(1.05) rotate(5deg);
    }
    100% {
      transform: translateY(0) scale(1) rotate(-5deg);
    }
  }

  &.bonus,
  .bonusClaimable {
    svg {
      width: 32px;
      height: 32px;
    }
  }
}
