.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin: 0;
}

.button {
  width: 100%;
}

.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 38px;
  position: relative;
}

.image {
  height: 370px;
  width: auto;
  max-width: 100%;
  transition: width 0.3s, height 0.3s;
  position: relative;
  z-index: 1;
}

.imageActive {
  height: 220px;
}

.shineBig {
  position: absolute;
  width: 276px;
  height: 192px;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 340px;
  background: #fcef5e;

  filter: blur(45px);
}

.shineBigSmall {
  width: 152px;
  height: 89px;

  filter: blur(40px);
}

.shineMid {
  position: absolute;
  width: 163px;
  height: 124px;
  top: 24%;
  left: 51%;

  border-radius: 340px;
  background: #ff7e00;
  opacity: 0.5;

  rotate: -41.34deg;
  filter: blur(80px);
}

.shineMidSmall {
  width: 109px;
  height: 83px;
  top: 27%;
  left: 49%;

  filter: blur(53px);
}

.shineLow {
  position: absolute;
  width: 127px;
  height: 85px;
  top: 26%;
  left: 27%;

  border-radius: 50%;
  background: #ff7e00;

  rotate: -41.34deg;

  filter: blur(40px);
  mix-blend-mode: screen;

  z-index: 10;
}

.shineLowSmall {
  width: 70px;
  height: 54px;
  left: 36%;

  filter: blur(27px);
}

.farmDescription {
  margin-bottom: 40px;

  &[disabled] {
    display: none;
  }
}

.balanceContainer {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 24px;

  border-radius: 54px;
  border: var(--primary-border);
  background: var(--primary-background-gradient);
}

.balance {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 32px;
}

.farmInfo {
  margin-bottom: 24px;

  &[disabled] {
    display: none;
  }
}

.farmInfo,
.baseFarm,
.bonusFarm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   position: relative;
}

.baseFarm {
  margin-bottom: 16px;
}

.baseFarmLable,
.bonusFarmLable {
  padding: 4px 12px;
  margin-bottom: 8px;

  border-radius: 100px;
}

.baseFarmLable {
  background-color: var(--primary-accent-color);
}

.bonusFarmLable {
  background: var(--primary-accent-gradient);
}

.buttonContainer {
  width: 100%;
}

.lvlInfo {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;

  opacity: 0.8;
}

.popupMessage {
  position: fixed;
  width: calc(100vw - 32px);
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 16px;

  color: white;
  background: var(--thirdy-accent-gradient);

  border-radius: 20px;
  text-align: center;
  white-space: wrap;

  box-shadow: 0px -24px 24px 0px #1d2d21;

  z-index: 100;
}

.buttonContainerWithTrap {
  position: relative;
}

.trapButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.buttonShifted {
  position: relative;
  z-index: 20;
  bottom: 60px;
  transition: margin-top 0.3s ease;
}
