.arrows {
  display: flex;
}

.arrow {
  animation-duration: 1.6s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
  margin-left: -1px;
}

.arrowsBig {
  & .arrow {
    width: 12px;
    height: 24px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMiAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjQ0OTIzIDIzLjc4NjRMMTEuNTc5OCAxMy40MTU5QzEyLjE0MDEgMTIuNzc5NiAxMi4xNDAxIDExLjc0NzggMTEuNTc5OCAxMS4xMTE0TDIuNDQ5MjMgMC43NDA5NTlDMS44ODg5NCAwLjEwNDU3NyAwLjk4MDUxOCAwLjEwNDU3NyAwLjQyMDIyMiAwLjc0MDk1OUMtMC4xNDAwNzQgMS4zNzczNCAtMC4xNDAwNzQgMi40MDkxMiAwLjQyMDIyMiAzLjA0NTVMOC4xNjIzIDExLjgzODlDOC4yNjU2MyAxMS45NTYzIDguMzIyNjQgMTIuMTA3MyA4LjMyMjY0IDEyLjI2MzdWMTIuMjYzN0M4LjMyMjY0IDEyLjQyIDguMjY1NjMgMTIuNTcxMSA4LjE2MjMgMTIuNjg4NEwwLjQyMDIyMiAyMS40ODE4Qy0wLjE0MDA3NCAyMi4xMTgyIC0wLjE0MDA3NCAyMy4xNSAwLjQyMDIyMiAyMy43ODY0QzAuOTgwNTE4IDI0LjQyMjggMS44ODg5NCAyNC40MjI4IDIuNDQ5MjMgMjMuNzg2NFoiIGZpbGw9IiNGRTkyMDMiLz4KPC9zdmc+Cg==");
    animation-name: bounceAlpha;
  }
}

.arrowsBig.red {
  & .arrow {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMiAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0NTJfMzUwOCkiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIuNDQ5MjMgMjMuNzg2NEwxMS41Nzk4IDEzLjQxNTlDMTIuMTQwMSAxMi43Nzk2IDEyLjE0MDEgMTEuNzQ3OCAxMS41Nzk4IDExLjExMTRMMi40NDkyMyAwLjc0MDk1OEMxLjg4ODk0IDAuMTA0NTc2IDAuOTgwNTE4IDAuMTA0NTc2IDAuNDIwMjIyIDAuNzQwOTU4Qy0wLjE0MDA3NCAxLjM3NzM0IC0wLjE0MDA3NCAyLjQwOTEyIDAuNDIwMjIyIDMuMDQ1NUw4LjE2MjMgMTEuODM4OUM4LjI2NTYzIDExLjk1NjMgOC4zMjI2NCAxMi4xMDczIDguMzIyNjQgMTIuMjYzN0M4LjMyMjY0IDEyLjQyIDguMjY1NjMgMTIuNTcxMSA4LjE2MjMgMTIuNjg4NEwwLjQyMDIyMiAyMS40ODE4Qy0wLjE0MDA3NCAyMi4xMTgyIC0wLjE0MDA3NCAyMy4xNSAwLjQyMDIyMiAyMy43ODY0QzAuOTgwNTE4IDI0LjQyMjggMS44ODg5NCAyNC40MjI4IDIuNDQ5MjMgMjMuNzg2NFoiIGZpbGw9IiNFQTM4MDAiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDUyXzM1MDgiPgo8cmVjdCB3aWR0aD0iMTIiIGhlaWdodD0iMjUiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
  }
}

.arrowsSmall {
  & .arrow {
    width: 6px;
    height: 12px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDYgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4yMjQ2MiAwLjk3Mzk5NUw1Ljc4OTg5IDYuMTU5MjJDNi4wNzAwNCA2LjQ3NzQxIDYuMDcwMDQgNi45OTMzIDUuNzg5ODkgNy4zMTE0OUwxLjIyNDYyIDEyLjQ5NjdDMC45NDQ0NjcgMTIuODE0OSAwLjQ5MDI1OCAxMi44MTQ5IDAuMjEwMTEgMTIuNDk2N0MtMC4wNzAwMzggMTIuMTc4NSAtMC4wNzAwMzc5IDExLjY2MjYgMC4yMTAxMSAxMS4zNDQ0TDQuMTYxMzIgNi44NTY2N0w0LjE2MTMyIDYuNjE0MDNMMC4yMTAxMTEgMi4xMjYyN0MtMC4wNzAwMzcxIDEuODA4MDggLTAuMDcwMDM3MSAxLjI5MjE5IDAuMjEwMTExIDAuOTczOTk1QzAuNDkwMjU5IDAuNjU1ODA0IDAuOTQ0NDY4IDAuNjU1ODA0IDEuMjI0NjIgMC45NzM5OTVaIiBmaWxsPSIjRkU5MjAzIi8+Cjwvc3ZnPgo=");
    animation-name: bounceAlphaSmall;
  }
}

.arrowSmall.red {
  & .arrow {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDYgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF80NDUyXzM1MTIpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjIyNDYyIDAuOTc0OTcxTDUuNzg5ODkgNi4xNjAyQzYuMDcwMDQgNi40NzgzOSA2LjA3MDA0IDYuOTk0MjggNS43ODk4OSA3LjMxMjQ3TDEuMjI0NjIgMTIuNDk3N0MwLjk0NDQ2OCAxMi44MTU5IDAuNDkwMjU5IDEyLjgxNTkgMC4yMTAxMTEgMTIuNDk3N0MtMC4wNzAwMzcgMTIuMTc5NSAtMC4wNzAwMzY5IDExLjY2MzYgMC4yMTAxMTEgMTEuMzQ1NEw0LjE2MTMyIDYuODU3NjVWNi42MTUwMUwwLjIxMDExMiAyLjEyNzI1Qy0wLjA3MDAzNjEgMS44MDkwNiAtMC4wNzAwMzYxIDEuMjkzMTcgMC4yMTAxMTIgMC45NzQ5NzFDMC40OTAyNiAwLjY1Njc4IDAuOTQ0NDY5IDAuNjU2NzggMS4yMjQ2MiAwLjk3NDk3MVoiIGZpbGw9IiNFQTM4MDAiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDUyXzM1MTIiPgo8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxMyIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K");
  }
}

.second {
  animation-delay:0.2s;
}

.third {
  animation-delay:0.4s;
}

@keyframes bounceAlpha {
  0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(20px) scale(0.8);}
  26%{opacity: 0; transform:translateX(-20px) scale(0.8);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
}
@keyframes bounceAlphaSmall {
  0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(10px) scale(0.8);}
  26%{opacity: 0; transform:translateX(-10px) scale(0.8);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
}