.notificationsButton {
  position: relative;
}

.notificationsIcon {
  fill: var(--primary-accent-color);

  &.notificationsIconActive {
    fill: var(--primary-border-color);
  }
}

.notificationDot {
  position: absolute;
  top: 4px;
  right: 3px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  outline: 1px solid var(--secondary-background-color);
}
