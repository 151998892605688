// Skills
.skillsList {
  max-height: unset;
  margin-bottom: 20px;

  overflow-y: unset;
}

.skillItem {
  display: flex;
  position: relative;
  background: var(--background-overlay);
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 10px;
  align-items: center;
  overflow: visible !important;
}

.skillItemWrapper {
  position: relative;
}

.iconWrapper {
  position: relative;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
}

.skillLevel {
  position: absolute;
  padding: 4px 12px;
  top: 0px;
  left: -4px;

  color: white;

  background: var(--primary-accent-gradient-v2);
  border-radius: 100px;

  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.skillLevelText {
  line-height: 14px;
  letter-spacing: 0.4px;
}

.skillLevelTextWide {
  letter-spacing: 0.3px;
}

.skillIconBtn {
  width: 44px;
  height: 44px;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: none;
}
.skillIcon {
  width: 44px;
  height: 44px;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.skillHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.skillInfo {
  flex-grow: 1;
}

.skillName {
  font-weight: bold;
  font-size: 18px;
  color: white;
  display: block;
  margin-bottom: 2px;
}

.skillBonus {
  font-size: 14px;
  color: #ff7f50;
}

.skillFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // gap: 53px;
}

.skillLevelBar {
  height: 4px;
  margin-right: 12px;
  flex: 0 0 66.5%;
}

.upgradeCost {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.upgradeSkillButton {
  width: 115px;
  border: none;
}

.coinIcon {
  flex-shrink: 0;
}
