.progressBar {
  background-color: rgba(255, 255, 255, 0.22);
  border-radius: 12px;

  overflow: hidden;
}

.progressBarFill {
  height: 100%;
  background-color: var(--secondary-accent-color);
  border-radius: 12px;
  transition: width 0.3s ease-out;
}
