.skillInfoModal {
  // Add any specific styles for the modal here
}

.backgroundWrapper {
  padding: 15.5vh 17px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  margin-bottom: 12px;
}

.skillIcon {
  min-width: 200px;
  min-height: 200px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillName {
  line-height: 44.8px;
}

.skillBonus {
  margin-bottom: 15px;
}

.skillLevelBar {
  max-width: 231px;
  width: 231px;
  height: 4px;
  margin-bottom: 20px;
  // flex: 0 0 66.5%;
}

.levelWrapper {
  width: 100%;
}

.descriptionBlock {
  width: 100%;
}

.description {
  text-align: center;
}
