.CircleWrapper {
  width: 16px;
  height: 16px;
  position: relative;

  & .Circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 3px;
      height: 3px;
      background-color: #fff;
      border-radius: 100%;
      animation: circleBounceDelay 1.2s infinite ease-in-out both;
    }

    &.disabled::before {
      background-color: rgba(255, 255, 255, 0.4);
    }

    &:nth-of-type(2) {
      transform: rotate(45deg);

      &::before {
        animation-delay: -0.7s;
      }
    }
    &:nth-of-type(3) {
      transform: rotate(90deg);

      &::before {
        animation-delay: -0.6s;
      }
    }
    &:nth-of-type(4) {
      transform: rotate(135deg);

      &::before {
        animation-delay: -0.5s;
      }
    }
    &:nth-of-type(5) {
      transform: rotate(180deg);

      &::before {
        animation-delay: -0.4s;
      }
    }
    &:nth-of-type(6) {
      transform: rotate(225deg);

      &::before {
        animation-delay: -0.3s;
      }
    }
    &:nth-of-type(7) {
      transform: rotate(270deg);

      &::before {
        animation-delay: -0.2s;
      }
    }
    &:nth-of-type(8) {
      transform: rotate(315deg);

      &::before {
        animation-delay: -0.1s;
      }
    }
  }
}

@keyframes circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
      transform: scale(1);
    }
}

.circleDark {
  & .Circle {
    &::before {
      background-color: #274523;
    }
    &.disabled::before {
      background-color: rgba(#274523, 0.4);
    }
  }
}