.bonus {
  background: var(--primary-accent-gradient-v2);
  padding: 4px 12px;
  border-radius: 100px;
}

.gameLevelTitle {
  display: block;
  margin-bottom: 5px;
}

// Game level
.gameLevelInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
}

.gameLevelBar {
  width: 70%;
  height: 8px;
  margin-top: 5px;
}

.balance {
  margin-bottom: 16px;
}

.upgradeSection {
  margin-bottom: 24px;
  text-align: center;
}

.upgradeButton {
  margin-bottom: 8px;
}

.upgradeGameLevelButton {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 12px 0px;
  gap: 0px;

  // background: var(--primary-accent-gradient);
  border: none;
  border-radius: 16px;

  box-shadow: 0px 4px 40px 0px #ef5a06;

  cursor: pointer;

  &[aria-disabled="true"] {
    pointer-events: all;
    background: var(--primary-accent-gradient-disabled);
    box-shadow: none;

    & > * {
      color: #fff;
    }
  }
}

.loading {
  min-height: 64px;

  & .buttonLoader {
    height: 30px;
  }
}

.rewardText {
  display: block;
  opacity: 0.8;
}

.freeUpgradeText {
  text-align: center;
  margin: 0px;
  // margin-bottom: 20px;
}

.skillGroup {
  margin-bottom: 24px;
  padding: 8px 12px;
}

.skillGroupItem {
  width: 48%;
  padding: 10px;
  background-color: #4a5d23;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.popupMessage {
  position: fixed;
  width: calc(100vw - 32px);
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 16px;

  color: white;
  background: var(--thirdy-accent-gradient);

  border-radius: 20px;
  text-align: center;
  white-space: wrap;

  box-shadow: 0px -24px 24px 0px #1d2d21;

  z-index: 100;
}
