.nav {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #18261C;
}

.navInner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 12px 16px;
  max-width: 600px;
  width: 100%;
}