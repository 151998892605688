.notification {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;

  // height: 70px;
  gap: 8px;
  margin-top: 16px;
  padding: 18px 16px;
  border-radius: 100px;
  border: 2px solid var(--thirdy-background-color);

  z-index: 2;
}

.textButton {
  display: -webkit-box;

  -webkit-line-clamp: 2;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  padding: 0;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
