.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.groupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.groupName {
  margin-bottom: 12px;
}

.levelsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.levelItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;

  background-color: var(--secondary-background-color);
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
}

.levelName {
  opacity: 0.8;
}

.rewardLable {
  display: flex;
  min-width: 141px;
  gap: 4px;
  padding: 4px 12px;

  justify-content: center;

  border-radius: 100px;
  background: var(--primary-accent-gradient-v2);
}

.coinIcon {
  flex-shrink: 0;
}

.rewardText {
  opacity: 0.5;
}
