.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  transition: transform .3s;
}
@media (hover: none) {
  .containerFocus {
    padding-bottom: 70px !important;
    transform: translateY(-140px);
  }
}
.image {
  width: calc(100% + 32px);
  max-width: 400px;
  height: auto;
  margin: -16px -16px 16px;
  @media (min-width: 500px) {
    margin: -16px auto 16px;
    
  }
}
.heading {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  margin-bottom: 40px;
}
.formField {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
}
.inputField {
  position: relative;
  width: 100%;

  & input {
    width: 100%;
    font-family: inherit;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: white;
    background-color: #2E5F26;
    border-radius: 20px;
    border: 1px solid transparent;
    padding: 14px 20px;
    caret-color: white;
    transition: border-color 0.3s ease;
    z-index: 10;

    &::placeholder {
      left: 20px;
      font-size: 18px;
      line-height: 26px;
      color: #9CDB8B;
      transition: color 0.3s ease;
    }
    &:hover {
      border-color: #FF772B
    }
    &:focus {
      border-color: #FF772B;
      box-shadow: 0 0 0 3px rgba(#FF772B, 0.5);

      &::placeholder {
        color: transparent;
      }
    }
    &.error {
      border-color: #FF0000;
      box-shadow: 0 0 0 3px rgba(#FF0000, 0.5);
    }
  }
  .errorField {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
  }
}
.emailSuccess {
  width: 100%;
  border-radius: 44px;
  background: #2E5F26;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
  gap: 12px;
}
.icon {
  box-shadow: 0px 2.667px 10.667px 0px rgba(80, 175, 149, 0.32);
  border-radius: 10.7px;
}