// WatchAdModal.module.scss
.watchAdModal {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  height: fit-content !important;
  overflow-y: hidden !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  padding: 0;
}

.shineMid {
  position: absolute;
  width: 163px;
  height: 124px;
  top: 24%;
  left: 51%;

  border-radius: 340px;
  background: #ff7e00;
  opacity: 0.5;

  rotate: -41.34deg;
  filter: blur(80px);
}

.shineLow {
  position: absolute;
  width: 70px;
  height: 85px;
  top: 30%;
  left: 27%;

  border-radius: 50%;
  background: #ff7e00;

  rotate: -41.34deg;

  filter: blur(40px);
  mix-blend-mode: screen;

  z-index: 10;
}

.shineLowSmall {
  width: 70px;
  height: 54px;
  left: 36%;

  filter: blur(27px);
}

.image {
  width: 240px;
  height: auto;
  position: relative;
  z-index: 1;
}

.balanceContainer {
  padding: 8px 24px;
  display: flex;
  flex-flow: column nowrap;

  border-radius: 37px;
  border: var(--primary-border);
  background: var(--primary-background-gradient);
}

.watchBtn {
  width: 100%;

  border-radius: 16px;

  box-shadow: 0px 4px 40px 0px #ef5a06;

  &[aria-disabled="true"] {
    pointer-events: all;
    background: var(--primary-accent-gradient-disabled);
    box-shadow: none;

    & > * {
      color: #fff;
    }
  }
}

.watchFinishedBtn {
  width: 100%;

  border-radius: 16px;
}
